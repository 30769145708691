// src/pages/account.js
import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import MediaQuery from 'react-responsive'
import { default as AntdLayout } from 'antd/lib/layout'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Header from '../components/Header/HeaderDoc'
import Helmet from 'react-helmet'
import Container from '../components/Container/ContainerTailor'
import useTitle from '../components/Title';


const PrivacyPolicy = () => {
    const intl = useIntl();
    const title = useTitle();
  
  return (
    <>
    <MediaQuery maxWidth={1000}>
    {matches => (
      <>
        <Helmet
          title={intl.formatMessage({ id: "title" })}
          meta={[
            { name: 'description for index', content: 'Sample for index' },
            { name: 'keywords for index', content: 'index' },
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <AntdLayout>
          <AntdLayout.Header
            style={{
              position: 'fixed',
              top: 0,
              width: '100%',
              zIndex: 100
            }}
          >
            <Row>
              <Col style={{ width:'100%' }}>
                <Header
                  siteTitle={title}
                  sidebarDocked={!matches}
                />
              </Col>
            </Row>
          </AntdLayout.Header>
        <Container>
        <div style={{maxWidth:'860px', margin:'5rem auto'}}>
          <h1><FormattedMessage id='privacy_title'/></h1>
          <p>
            <FormattedMessage id='privacy_sub1'/>
          </p>
          <p><FormattedMessage id='privacy_sub1_p1'/></p>
          <p><FormattedMessage id='privacy_sub2'/></p>
          <ol>
              <li><FormattedMessage id='privacy_sub2_li1'/></li>
              <li><FormattedMessage id='privacy_sub2_li2'/></li>
              <li><FormattedMessage id='privacy_sub2_li3'/></li>
              <li><FormattedMessage id='privacy_sub2_li4'/></li>
              <li><FormattedMessage id='privacy_sub2_li5'/></li>
              <li><FormattedMessage id='privacy_sub2_li6'/></li>
              <li><FormattedMessage id='privacy_sub2_li7'/></li>
          </ol>
          <p><FormattedMessage id='privacy_sub3'/></p>
          <ol>
              <li><FormattedMessage id='privacy_sub3_li1'/></li>
              <li><FormattedMessage id='privacy_sub3_li2'/></li>
              <li><FormattedMessage id='privacy_sub3_li3'/></li>
          </ol>
          <p><FormattedMessage id='privacy_sub4'/></p>
          <p><FormattedMessage id='privacy_sub4_p1'/></p>

          <p><FormattedMessage id='privacy_sub5'/></p>
          <p><FormattedMessage id='privacy_sub5_p1'/></p>
          <ol>
              <li><FormattedMessage id='privacy_sub5_li1'/></li>
              <li><FormattedMessage id='privacy_sub5_li2'/></li>
              <li><FormattedMessage id='privacy_sub5_li3'/></li>
              <li><FormattedMessage id='privacy_sub5_li4'/></li>
          </ol>
          <p><FormattedMessage id='privacy_sub6'/></p>
          <ol>
              <li><FormattedMessage id='privacy_sub6_li1'/></li>
              <li><FormattedMessage id='privacy_sub6_li2'/></li>
              <li><FormattedMessage id='privacy_sub6_li3'/></li>
          </ol>
          <p><FormattedMessage id='privacy_sub7'/></p>
          <p><FormattedMessage id='privacy_sub7_p1'/></p>

          <p><FormattedMessage id='privacy_sub8'/></p>
          <p><FormattedMessage id='privacy_sub8_p1'/></p>

          <p><FormattedMessage id='privacy_sub9'/></p>
          <p><FormattedMessage id='privacy_sub9_p1'/></p>
        </div>
      </Container>

      </AntdLayout>
          </>
        )}
      </MediaQuery>
    </>
  )
}

export default PrivacyPolicy